<template>
  <vx-card class="data-thumb-block cursor-pointer" @click="$router.push(`/captures/${data.id}`)" @mouseover="hover = true" @mouseout="hover = false">
    <div class="vx-row -mx-2 flex-no-wrap">
      <div
        class="col-thumb vx-col px-2 w-48 flex items-center justify-center pl-0 -ml-2"
        :style="{ 'background-image': `url(${screenshot || '/assets/images/placeholder.png'})` }"
      >
        <transition name="fade">
          <div v-show="hover">
            <div class="flex">
              <vs-button radius icon-pack="feather" icon="icon-eye" color="primary" class="mr-2" @click.stop="$router.push(`/captures/${data.id}`)">
              </vs-button>
              <vs-button
                radius
                icon-pack="feather"
                icon="icon-trash-2"
                color="danger"
                @click.stop="
                  $vs.dialog({
                    type: 'confirm',
                    color: 'danger',
                    title: 'Confirm Delete',
                    text: `Are you sure? You're about to delete this capture.`,
                    accept: deleteCapture,
                    acceptText: 'Yes, delete it!'
                  })
                "
              >
              </vs-button>
            </div>
          </div>
        </transition>
      </div>
      <div class="vx-col px-2 pl-4 flex-grow flex flex-col">
        <div class="flex justify-between mb-3 flex-grow">
          <div class="max-w-xs truncate">
            <span class="font-semibold text-sm">{{ data.title || 'No title' }}</span>
            <p v-if="data.url" class="font-light text-xs my-2 text-primary max-w-xs truncate">
              <vs-icon icon-pack="feather" icon="icon-link" class="mr-1 text-xs"></vs-icon>
              {{ data.url | urlPath }}
            </p>
            <p class="font-light text-xs max-w-xs truncate">
              <vs-icon icon-pack="feather" icon="icon-clock" class="mr-1 text-xs"></vs-icon>
              {{ data.dateCaptured | moment('DD MMM, YYYY HH:mm (z)', config.timeZone) }}
            </p>
          </div>
          <div class="ml-auto flex items-center self-start">
            <vs-chip v-for="(screenshot, i) in (data.screenshots || []).slice(0, 1)" :key="i" class="text-xs">
              <small class="font-semibold">
                {{ `${screenshot.viewPort.width}x${screenshot.viewPort.height}` }}
              </small>
            </vs-chip>
            <span v-if="(data.screenshots || []).length > 1" class="text-xs font-semibold">+{{ (data.screenshots || []).length - 1 }}</span>
          </div>
        </div>
        <vs-divider />
        <div class="flex text-2xs">
          <span class="flex uppercase">
            <vs-icon
              icon-pack="feather"
              :icon="data.captureHtml ? 'icon-check' : 'icon-x'"
              :color="data.captureHtml ? 'success' : 'danger'"
              class="mr-1 text-xs relative"
              style="top: -1px;"
            ></vs-icon>
            Capture HTML
          </span>
          <span class="flex ml-5 uppercase">
            <vs-icon
              icon-pack="feather"
              :icon="data.captureHar ? 'icon-check' : 'icon-x'"
              :color="data.captureHar ? 'success' : 'danger'"
              class="mr-1 text-xs relative"
              style="top: -1px;"
            ></vs-icon>
            Capture HAR
          </span>
          <span class="flex ml-5 uppercase">
            <vs-icon
              icon-pack="feather"
              :icon="data.captureHar ? 'icon-check' : 'icon-x'"
              :color="data.captureHar ? 'success' : 'danger'"
              class="mr-1 text-xs relative"
              style="top: -1px;"
            ></vs-icon>
            Capture Web Archive
          </span>
          <span class="flex ml-auto uppercase">
            <vs-icon icon-pack="feather" icon="icon-rotate-ccw" class="mr-1 text-xs relative" style="top: -1px;"></vs-icon>
            {{ data.detectedChangePercent }}% change{{ data.detectedChangePercent > 1 ? 's' : '' }}
          </span>
        </div>
      </div>
    </div>
  </vx-card>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true
    },
    config: {
      type: Object
    }
  },
  data() {
    return {
      hover: false
    };
  },
  computed: {
    screenshot() {
      try {
        const screenshots = this.data.screenshots;
        for (let i = 0; i < screenshots.length; i++) {
          const item = screenshots[i];
          const { image } = item;
          if ((image || {}).thumbnail) return image.thumbnail;
          if (item.screenShotPath) return item.screenShotPath;
        }
      }
      catch (error) {
        return false;  
      }
      return false;
    }
  },
  methods: {
    async deleteCapture() {
      await this.$store.dispatch('captures/delete', { id: this.data.id });
    }
  },
  created() {},
  mounted() {}
};
</script>
