<template>
  <section class="capture-list">
    <div class="vx-row -mx-3 flex-row md:flex-row-reverse">
      <div class="vx-col px-3 w-full md:w-1/3">
        <vx-card class="mb-5">
          <div class="vx-row">
            <div class="vx-col w-full  mb-5">
              <vx-field
                type="datetime"
                class="w-full"
                placeholder="Date range"
                v-model="form.datecaptured"
                icon="icon-calendar"
                @on-change="dateChanged"
                :config="{
                  dateFormat: 'd-m-Y',
                  enableTime: false,
                  mode: 'range'
                }"
              />
            </div>
            <div class="vx-col w-full">
              <vx-field
                type="select"
                class="w-full"
                autocomplete
                placeholder="Filter by domain"
                v-model="filters['url.host.id']"
                :options="{
                  items: domains
                }"
              />
            </div>
          </div>
        </vx-card>
      </div>
      <div class="vx-col px-3 w-full md:w-2/3">
        <vx-card>
          <div slot="no-body" class="flex p-5">
            <vx-field
              icon="icon-search"
              icon-pack="feather"
              type="input"
              class="w-full"
              placeholder="Start typing to filter captures by title or URL"
              v-model="filters['q']"
            />
            <vs-tooltip text="Thumbnail View">
              <button
                @click="disabledThumbnail = false"
                type="button"
                :class="{ 'bg-primary': !disabledThumbnail }"
                class="vs-component vs-button ml-2 vs-button-primary vs-button-border includeIcon includeIconOnly"
              >
                <i
                  :class="{ 'text-white': !disabledThumbnail }"
                  class="vs-icon notranslate icon-scale vs-button--icon text-xl feather icon-list null"
                ></i>
              </button>
              <span></span>
            </vs-tooltip>
            <vs-tooltip text="List View">
              <button
                @click="disabledThumbnail = true"
                type="button"
                :class="{ 'bg-primary': disabledThumbnail }"
                class="vs-component vs-button ml-2 vs-button-primary vs-button-border includeIcon includeIconOnly"
              >
                <i
                  :class="{ 'text-white': disabledThumbnail }"
                  class="vs-icon notranslate icon-scale vs-button--icon text-xl feather icon-align-justify null"
                ></i>
              </button>
              <span></span>
            </vs-tooltip>
          </div>
        </vx-card>
        <LoaderCaptureList :class="{ 'vx-data-list-captures--list': disabledThumbnail }" v-if="loading"></LoaderCaptureList>
        <vx-data-list
          v-else
          class="vx-data-list-captures"
          :class="{ 'vx-data-list-captures--list': disabledThumbnail }"
          ref="vxDataList"
          :hoverFlat="true"
          :data="list"
          :columns="columns"
          :search="false"
          :config="{
            disablePager: true,
            selectable: true
          }"
          @selected="tr => $router.push(`/captures/${tr.id}`)"
          @filter="filter"
        />
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import CaptureThumbnail from '@/views/captures/components/CaptureThumbnail';
import LoaderCaptureList from '@/views/captures/components/loader/CaptureList';
import Paging from '@/entity/Paging';
import { debounce } from '@/plugins/helper.js';

export default {
  name: 'CapturesList',
  data() {
    return {
      loading: false,
      filters: {},
      form: {
        datecaptured: ''
      },
      disabledThumbnail: false
    };
  },
  computed: {
    ...mapState('captures', ['list', 'hosts', 'tags']),
    domains() {
      return (this.hosts.items || []).map(item => ({
        value: item.host.id,
        text: this.$options.filters.urlPath(item.host)
      }));
    },
    dateSelectionLimit() {
      if (!this.form.datecaptured) return {};
      const [x] = this.form.datecaptured.split('to');
      const strFormat = 'DD-MM-YYYY';
      const minDate = this.$moment(x.trim(), strFormat)
        .subtract(1, 'year')
        .toDate();
      const maxDate = this.$moment(x.trim(), strFormat)
        .add(1, 'year')
        .toDate();
      return {
        minDate,
        maxDate
      };
    },
    columns() {
      return !this.disabledThumbnail
        ? [
            {
              component: CaptureThumbnail
            }
          ]
        : [
            {
              label: 'Title',
              field: 'title',
              classes: 'truncate max-w-48',
              renderer: 'VxDataRendererText'
            },
            {
              label: 'URL',
              classes: 'truncate max-w-48',
              value: data => {
                return this.$options.filters.urlPath(data.url);
              },
              renderer: 'VxDataRendererText'
            },
            {
              label: 'Last Capture Time',
              renderer: data => this.$options.filters.moment(data.dateCaptured)
            },
            {
              label: '% Change',
              classes: 'text-right',
              renderer: data => `${data.detectedChangePercent}%`
            },
            {
              label: 'HTML',
              icon: data => (data.captureHtml ? 'CheckCircleIcon' : 'XCircleIcon'),
              color: data => (data.captureHtml ? 'text-success' : 'text-danger'),
              classes: 'text-center',
              renderer: 'VxDataRendererIcon'
            },
            {
              label: 'HAR',
              icon: data => (data.captureHar ? 'CheckCircleIcon' : 'XCircleIcon'),
              color: data => (data.captureHar ? 'text-success' : 'text-danger'),
              classes: 'text-center',
              renderer: 'VxDataRendererIcon'
            },
            {
              label: 'Web Archive',
              icon: data => (data.captureWarc ? 'CheckCircleIcon' : 'XCircleIcon'),
              color: data => (data.captureWarc ? 'text-success' : 'text-danger'),
              classes: 'text-center',
              renderer: 'VxDataRendererIcon'
            }
          ];
    }
  },
  methods: {
    filter(paging) {
      this.fetch({ paging });
    },
    async fetch({ params, paging } = {}) {
      const dates = this.form.datecaptured || '';
      const [x, y] = dates.split('to');
      const start = this.$moment((x || '').trim(), 'DD-MM-YYYY')
        .utc()
        .startOf('day')
        .format();
      const end = this.$moment((y || x || '').trim(), 'DD-MM-YYYY')
        .utc()
        .endOf('day')
        .format();

      this.loading = true;
      await this.$store.dispatch('captures/list', {
        params: {
          ...(params || this.filters),
          ...(dates
            ? {
                datecaptured: [start, end].join('/')
              }
            : {})
        },
        paging: paging || new Paging(),
        config: {
          disablePreload: true
        }
      });
      this.loading = false;
    },
    /* eslint no-unused-vars: 0 */
    dateChanged([from, to], valueStr) {
      if (to) {
        this.form.datecaptured = valueStr;
        this.$nextTick(() => this.fetch());
      }
    }
  },
  watch: {
    filters: {
      deep: true,
      handler: debounce(async function(params) {
        await this.fetch({ params });
      }, 350)
    }
  },
  async created() {
    const { query } = this.$route;
    if (query.datecaptured) {
      this.form.datecaptured = query.datecaptured;
    }

    await this.fetch();
  },
  components: {
    CaptureThumbnail,
    LoaderCaptureList
  }
};
</script>
