var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"capture-list"},[_c('div',{staticClass:"vx-row -mx-3 flex-row md:flex-row-reverse"},[_c('div',{staticClass:"vx-col px-3 w-full md:w-1/3"},[_c('vx-card',{staticClass:"mb-5"},[_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col w-full  mb-5"},[_c('vx-field',{staticClass:"w-full",attrs:{"type":"datetime","placeholder":"Date range","icon":"icon-calendar","config":{
                dateFormat: 'd-m-Y',
                enableTime: false,
                mode: 'range'
              }},on:{"on-change":_vm.dateChanged},model:{value:(_vm.form.datecaptured),callback:function ($$v) {_vm.$set(_vm.form, "datecaptured", $$v)},expression:"form.datecaptured"}})],1),_c('div',{staticClass:"vx-col w-full"},[_c('vx-field',{staticClass:"w-full",attrs:{"type":"select","autocomplete":"","placeholder":"Filter by domain","options":{
                items: _vm.domains
              }},model:{value:(_vm.filters['url.host.id']),callback:function ($$v) {_vm.$set(_vm.filters, 'url.host.id', $$v)},expression:"filters['url.host.id']"}})],1)])])],1),_c('div',{staticClass:"vx-col px-3 w-full md:w-2/3"},[_c('vx-card',[_c('div',{staticClass:"flex p-5",attrs:{"slot":"no-body"},slot:"no-body"},[_c('vx-field',{staticClass:"w-full",attrs:{"icon":"icon-search","icon-pack":"feather","type":"input","placeholder":"Start typing to filter captures by title or URL"},model:{value:(_vm.filters['q']),callback:function ($$v) {_vm.$set(_vm.filters, 'q', $$v)},expression:"filters['q']"}}),_c('vs-tooltip',{attrs:{"text":"Thumbnail View"}},[_c('button',{staticClass:"vs-component vs-button ml-2 vs-button-primary vs-button-border includeIcon includeIconOnly",class:{ 'bg-primary': !_vm.disabledThumbnail },attrs:{"type":"button"},on:{"click":function($event){_vm.disabledThumbnail = false}}},[_c('i',{staticClass:"vs-icon notranslate icon-scale vs-button--icon text-xl feather icon-list null",class:{ 'text-white': !_vm.disabledThumbnail }})]),_c('span')]),_c('vs-tooltip',{attrs:{"text":"List View"}},[_c('button',{staticClass:"vs-component vs-button ml-2 vs-button-primary vs-button-border includeIcon includeIconOnly",class:{ 'bg-primary': _vm.disabledThumbnail },attrs:{"type":"button"},on:{"click":function($event){_vm.disabledThumbnail = true}}},[_c('i',{staticClass:"vs-icon notranslate icon-scale vs-button--icon text-xl feather icon-align-justify null",class:{ 'text-white': _vm.disabledThumbnail }})]),_c('span')])],1)]),(_vm.loading)?_c('LoaderCaptureList',{class:{ 'vx-data-list-captures--list': _vm.disabledThumbnail }}):_c('vx-data-list',{ref:"vxDataList",staticClass:"vx-data-list-captures",class:{ 'vx-data-list-captures--list': _vm.disabledThumbnail },attrs:{"hoverFlat":true,"data":_vm.list,"columns":_vm.columns,"search":false,"config":{
          disablePager: true,
          selectable: true
        }},on:{"selected":function (tr) { return _vm.$router.push(("/captures/" + (tr.id))); },"filter":_vm.filter}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }