<template>
  <vx-card
    class="data-thumb-block cursor-pointer">
    <div class="vx-row -mx-2 flex-no-wrap">
      <div class="col-thumb vx-col px-2 w-48 flex items-center justify-center pl-0 -ml-2"
        :style="{ 'background-image': `url('/assets/images/placeholder.png')` }">
      </div>
      <div class="vx-col px-2 pl-4 flex-grow flex flex-col">
        <div class="flex justify-between mb-3 flex-grow">
          <div class="w-full">
            <div class="mb-2 h-5 w-full overflow-hidden"><ContentLoader></ContentLoader></div>
            <p class="mb-1 h-4 w-1/2 overflow-hidden">
              <ContentLoader></ContentLoader>
            </p>
            <p class="h-4 w-1/3 overflow-hidden">
              <ContentLoader></ContentLoader>
            </p>
          </div>
        </div>
        <vs-divider class="mb-0" />
        <div class="flex pt-1">
          <span class="h-3 w-18 overflow-hidden"><ContentLoader></ContentLoader></span>
          <span class="ml-5 h-3 w-18 overflow-hidden"><ContentLoader></ContentLoader></span>
          <span class="ml-5 h-3 w-18 overflow-hidden"><ContentLoader></ContentLoader></span>
          <span class="ml-auto h-3 w-12 overflow-hidden"><ContentLoader></ContentLoader></span>
        </div>
      </div>
    </div>
  </vx-card>
</template>

<script>
import { ContentLoader } from 'vue-content-loader'
export default {
  components: {
    ContentLoader
  }
}
</script>

