<template>
  <vx-data-list
    class="vx-data-list-captures"
    ref="vxDataList"
    :hoverFlat="true"
    :data="list"
    :columns="columns"
    :search="false"
    :config="{
      disablePager: true,
      selectable: false
    }"
  />
</template>

<script>
import CaptureThumbnail from '@/views/captures/components/loader/CaptureThumbnail';
export default {
  data() {
    return {
      list: [],
      columns: [
        {
          component: CaptureThumbnail
        }
      ]
    };
  },
  methods: {
    generateMockupData() {
      let count = 6;
      while (count > 0) {
        this.list.push({});
        count--;
      }
    }
  },
  created() {
    this.generateMockupData();
  }
};
</script>
